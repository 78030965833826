import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import { classNames } from "../utils/formatters";

export default function VerifiedUserBadge({
  idVerified,
  className,
  triangle,
}: {
  className?: string;
  idVerified?: boolean;
  triangle?: boolean;
}) {
  const WarningIcon = triangle
    ? ExclamationTriangleIcon
    : ExclamationCircleIcon;
  const Icon = idVerified ? CheckBadgeIcon : WarningIcon;
  return (
    <Icon
      className={classNames(
        "flex-shrink-0",
        idVerified ? "text-cyan-500" : "text-yellow-500",
        className ? className : "h-10 w-10"
      )}
      aria-hidden="true"
    />
  );
}
