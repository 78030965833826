/* This example requires Tailwind CSS v2.0+ */

/* user declaration in line 65 is being casted to type "{}" automatically so down the file when
    trying to access properties from user, typescript is throwing errors. 
    I could why is begin directly casted into "{}" type.
 */
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  BellIcon,
  HeartIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusIcon, UserIcon } from "@heroicons/react/24/solid";
import { signOut, useSession } from "next-auth/react";
import { useRouter } from "../node_modules/next/router";
import Link from "next/link";
import { classNames } from "../utils/formatters";
import SearchField from "./SearchField";
import Avatar from "./Avatar";
import VerifiedUserBadge from "./VerifiedUserBadge";
import Image from "next/image";
import UserInfoBadge from "./UserInfoBadge";

const navigation = [
  { name: "Vender vehículo", href: "/vender" },
  { name: "Búsqueda", href: "/autosusados" },
  { name: "Precios", href: "/pricing" },
];

const getUserNavigation = (userParam) =>
  userParam
    ? [
        { name: "Perfil", href: "/profile/edit" },
        {
          name: "Mis Publicaciones",
          href: `/${userParam?.username ? userParam?.username : "/"}`,
        },
        { name: "Mis Compras", href: `/purchases` },
        { name: "Favoritos", href: `/favorites` },
        {
          name: "Cerrar Sesión",
          onClick: () => signOut({ callbackUrl: `${window.location.origin}/` }),
        },
      ]
    : [{ name: "Iniciar Sesión", href: "/auth/signin" }];

export default function Navbar() {
  const session = useSession();
  const sessionData = session?.data;
  const user = sessionData?.user;
  const router = useRouter();

  const userNavigation = getUserNavigation(user);

  const goToHomepage = () => router.push("/");
  const goToAdd = () => router.push("/vender");
  const goToSignIn = () => router.push("/api/auth/signin");

  return (
    <Disclosure as="nav" className="bg-white sticky top-0 z-40 shadow-md">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 pb-3 sm:pb-0">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-orange-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div
                  className="flex-shrink-0 flex items-center cursor-pointer"
                  onClick={goToHomepage}
                >
                  {/* <div className="hidden sm:flex lg:hidden">
                    <Image
                      src="/movi_logo_min.png"
                      alt="Movi"
                      width={62}
                      height={32}
                    />
                  </div> */}
                  <div className="flex">
                    <Image
                      src="/movi_logo.png"
                      alt="Logo Movi"
                      width={140}
                      height={32}
                    />
                  </div>
                </div>
                {/* <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      href={item.href}
                      aria-current={
                        item.href === router.pathname ? "page" : undefined
                      }
                    >
                      <span
                        className={classNames(
                          item.href === router.pathname
                            ? "bg-white text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-gray-500",
                          "px-3 py-2 rounded-md text-sm font-medium cursor-pointer"
                        )}
                      >
                        {item.name}
                      </span>
                    </Link>
                  ))}
                </div> */}
              </div>
              <div className="hidden sm:flex flex-1 items-center mx-2 justify-end sm:justify-start ">
                <SearchField />
              </div>
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <button
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 hover:border-orange-600 focus:outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500"
                    onClick={goToAdd}
                  >
                    <PlusIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span>Vender</span>
                  </button>
                </div>
                <div className="hidden md:ml-2 md:flex-shrink-0 md:flex md:items-center">
                  {/* <button
                    type="button"
                    className="bg-white p-1 rounded-full text-gray-600 hover:text-gray-500 mx-2"
                  >
                    ENG
                  </button> */}

                  {session.status === "authenticated" ? (
                    <Link href="/favorites" className="mx-2 hidden lg:flex">
                      <div className="cursor-pointer bg-white p-1 rounded-full text-gray-600 hover:text-gray-500 focus:outline-none flex flex-col items-center">
                        <span className="sr-only">Favoritos</span>
                        <HeartIcon className="h-6 w-6" aria-hidden="true" />
                        <span className="text-xxs">Favoritos</span>
                      </div>
                    </Link>
                  ) : null}

                  {/* {session.status === "authenticated" ? (
                    <button
                      type="button"
                      className="hidden lg:flex bg-white p-1 rounded-full text-gray-600 hover:text-gray-500 focus:outline-none flex-col items-center mx-2"
                    >
                      <span className="sr-only">Notificaciones</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                      <span className="text-xxs">Notificaciones</span>
                    </button>
                  ) : null} */}

                  {/* Profile dropdown */}
                  {session.status === "authenticated" ? (
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className="bg-white flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                          <span className="sr-only">Open user menu</span>
                          <Avatar
                            src={user?.company?.logo || user?.image || ""}
                            username={user?.username}
                            idVerified={user?.idVerified}
                            size="small"
                            nav
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {!user?.idVerified ? (
                            <Menu.Item>
                              {({ active }) => (
                                <Link href="/profile/edit" passHref={true}>
                                  <span
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "flex px-4 py-2 text-sm text-gray-700",
                                      "hover:bg-gray-100 cursor-pointer"
                                    )}
                                  >
                                    Verificar Identidad
                                    <VerifiedUserBadge
                                      idVerified={false}
                                      className="ml-1 h-5 w-5"
                                    />
                                  </span>
                                </Link>
                              )}
                            </Menu.Item>
                          ) : null}
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) =>
                                item.href ? (
                                  <Link
                                    key={item.name as string}
                                    href={item.href}
                                    passHref={true}
                                  >
                                    <span
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700",
                                        "hover:bg-gray-100 cursor-pointer"
                                      )}
                                    >
                                      {item.name}
                                    </span>
                                  </Link>
                                ) : (
                                  <button
                                    key={item.name as string}
                                    onClick={item.onClick}
                                    className="w-full text-left"
                                  >
                                    <span
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700",
                                        "hover:bg-gray-100 cursor-pointer"
                                      )}
                                    >
                                      {item.name}
                                    </span>
                                  </button>
                                )
                              }
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  ) : (
                    <button
                      type="button"
                      className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 hover:border-orange-600 focus:outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500"
                      onClick={goToSignIn}
                    >
                      <UserIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      <span>Iniciar sesión</span>
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-1 items-center sm:hidden">
              <SearchField />
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="pt-4 pb-3 border-t border-gray-300">
              <div className="flex items-center px-5 sm:px-6">
                {user ? (
                  <div className="flex-shrink-0">
                    <UserInfoBadge user={user} />
                  </div>
                ) : null}
                {/* <div className="ml-3">
                  <div className="text-base font-medium text-white">
                    {user.name}
                  </div>
                  <div className="text-sm font-medium text-gray-600">
                    {user.email}
                  </div>
                </div> */}
                {user ? (
                  <button
                    type="button"
                    className="ml-auto flex-shrink-0 bg-white p-1 rounded-full text-gray-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                ) : null}
              </div>
              <div className="mt-3 px-2 space-y-1 sm:px-3">
                {/* className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700",
                                      "hover:bg-gray-100 cursor-pointer"
                                    )} */}
                {userNavigation.map((item) =>
                  item.href ? (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        "block px-3 py-2 rounded-md text-base font-medium",
                        item.href === router.pathname ||
                          (item.href === `/${user?.username}` &&
                            router.pathname === "/[username]")
                          ? "bg-white text-orange-500"
                          : "text-gray-700 hover:bg-gray-100"
                      )}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ) : (
                    <Disclosure.Button
                      key={item.name}
                      as="button"
                      onClick={item.onClick}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-700  hover:bg-gray-100 w-full text-left"
                    >
                      {item.name}
                    </Disclosure.Button>
                  )
                )}
              </div>
            </div>
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 border-t border-t-gray-300">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.href === router.pathname
                      ? "bg-white text-orange-500"
                      : "text-gray-700 hover:bg-gray-100",
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                  aria-current={
                    item.href === router.pathname ? "page" : undefined
                  }
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
