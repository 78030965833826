import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import Avatar from "./Avatar";
import { User as SessionUser } from "next-auth";
import { User } from "../graphql/generated/graphql";
export default function UserInfoBadge({
  user,
  seller,
}: {
  user: User | SessionUser;
  seller?: boolean;
}) {
  let displayName = user.displayName ? user.displayName : user.username;

  if (user.company?.name) {
    displayName = user.company.name;
  }

  return (
    <div className="flex gap-x-4 items-center">
      <div className="flex-shrink-0 sm:mb-0">
        <Avatar
          src={user.company?.logo ? user.company.logo : user.image}
          username={user.username}
          idVerified={user.idVerified}
        />
      </div>
      <div>
        <h4 className="text-lg font-bold">{displayName}</h4>
        <p className="text-gray-600">
          {seller ? <span>Vendedor - </span> : null}
          Identidad {user.idVerified ? "verificada" : "no verificada"}
        </p>
        <Link href={`/${user.username}`}>
          <span className="text-cyan-500 hover:text-cyan-400 flex gap-x-1 items-center cursor-pointer font-medium">
            Ver página
            <ArrowTopRightOnSquareIcon className="h-4 w-4 " />
          </span>
        </Link>
      </div>
    </div>
  );
}
