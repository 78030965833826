import Image from "next/image";
import { useRouter } from "next/router";
import { classNames } from "../utils/formatters";
import VerifiedUserBadge from "./VerifiedUserBadge";

const SIZES = {
  small: "h-8 w-8",
  medium: "h-16 w-16",
  large: "h-20 w-20",
};

const BADGE_SIZES = {
  small: "h-4 w-4",
  medium: "h-6 w-6",
  large: "h-7 w-7",
};

export default function Avatar({
  src,
  username,
  idVerified,
  size,
  nav,
}: {
  src: string;
  username: string;
  idVerified: boolean;
  size?: "small" | "medium" | "large";
  nav?: boolean;
}) {
  const router = useRouter();

  function goToPage() {
    if (!nav) {
      router.push(`/${username}`);
    }
  }

  return (
    <div
      className={classNames(
        "inline-block relative",
        SIZES[size] || SIZES.medium
      )}
    >
      {src ? (
        <Image
          fill
          style={{
            objectFit: "cover",
          }}
          sizes="80px"
          className="w-full  h-full object-center object-cover rounded-full cursor-pointer"
          src={src}
          alt="User avatar"
          onClick={goToPage}
        />
      ) : (
        <div className="h-full w-full rounded-full overflow-hidden bg-gray-100">
          <svg
            className="h-full w-full text-gray-400"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </div>
      )}
      {/* <span className="absolute top-0 right-0 block h-4 w-4 rounded-full ring-2 ring-white bg-green-400" /> */}
      <div
        className={classNames(
          "absolute bottom-0 -right-1 rounded-full bg-white flex items-center justify-center border border-gray-200 shadow-sm",
          BADGE_SIZES[size] || BADGE_SIZES.medium
        )}
      >
        <VerifiedUserBadge className="h-full w-full" idVerified={idVerified} />
      </div>
    </div>
  );
}
